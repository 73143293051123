
































import { Component, Mixins } from "vue-property-decorator";
import HelperDinamicallyForm from "@/components/DinamicallyForm/HelperDinamicallyForm";
import { Usuario } from "@/shared/dtos/usuario";
import fichajes_empleadoModule from "@/store/modules/fichajes_empleado-module";

@Component({
  components: {
    FullDynamicTable: () => import("../dynamic_tables/full_dynamic_table.vue"),
  },
})
export default class UsuariosFichajes extends Mixins(HelperDinamicallyForm) {

  public async OnCreated() {
    this.ShowLoading();

    if (this.getparamId().HayParametro) {

      await fichajes_empleadoModule.getfichajes_empleadosByIdUsuario(
       this.getparamId().Parametro
      );
    }
  }

  async refresh_fichajes_empleado() {
    debugger;
    await fichajes_empleadoModule.getfichajes_empleadosByIdUsuario(
      this.getparamId().Parametro
    );
  }

  public CreateNameTabs() {
  }

  public CreateFields() {
    this.AddFormItemDataDefaultSlot(
      fichajes_empleadoModule.fichajes_empleados,
      "Historial de fichajes",
      "fichajes_empleados",
      2,
      1
    )
      .Dimesiones(12, 12, 12)
      .isVisible(!this.EstoyCreado);
  }

  public Insertar(object: Usuario) {
  }

  public Actualizar(object: Usuario) {
  }
}
